import React, { useState, useEffect } from "react";
import { Card, Button, Media, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import AddTeamsModal from "./modals/AddTeamsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faSync, faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";
import SwitchTeamModal from "./modals/SwitchTeamModal";
import EditPlaceHolderModal from "./modals/EditPlaceHolderModal";

const TeamsListCard = ({ league, setLeague, permission }) => {
  const { id: leagueId } = useParams();
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [switchingTeam, setSwitchingTeam] = useState(false);
  const [filterTeams, setFilterTeams] = useState([]);
  const [editPlaceHolderTeam, setEditPlaceHolderTeam] = useState(false);
  const [showEditPlaceHolderModal, setShowEditPlaceHolderModal] = useState(false);

  useEffect(() => {
    gettingIdsOfMatchTeams();
  }, [league]);

  const gettingIdsOfMatchTeams = () => {
    let currentMatchTeamsIds = [];
    league.matches.forEach((match) => {
      currentMatchTeamsIds.push(match.t1?._id, match.t2?._id);
    });

    let filter = [];
    league.teams.forEach((el) => {
      if (currentMatchTeamsIds.includes(el?.team?._id)) {
        filter.push(el.team?._id);
      }
    });
    setFilterTeams(filter);
  };

  const onRemoveTeam = async (id) => {
    const { name, initials, logo, game, placeHolder, _id: currentTeamId } = league.teams.find((team) => team._id === id).team;
    const placeholderTeamData = { name, initials, logo, league, game, organisation: "61dc169b2a8c7f1cb4d65cd2", placeHolder };

    if (placeholderTeamData.placeHolder === false) {
      console.log("aaa");
      try {
        const bodyData = {
          name: placeholderTeamData.name,
          initials: placeholderTeamData.initials,
          league: placeholderTeamData.league._id,
          game: placeholderTeamData.game,
          organisation: placeholderTeamData.organisation,
          placeHolder: true,
          logo: placeholderTeamData.logo,
        };
        // return;

        const { dataSwitch } = await Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}/matches/placeHolderSwitchTeam`,
          {
            currentTeamId,
            newTeam: bodyData,
          }
        );

        setLeague((league) => ({ ...league, teams: league.teams.filter(({ _id }) => _id !== id) }));
        toast.success("Successfully Replace with Placeholder team");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        toast.error("Replacing with Placeholder team");
      }
    } else {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/tournaments/${leagueId}/teams/${id}`);

        setLeague((league) => ({ ...league, teams: league.teams.filter(({ _id }) => _id !== id) }));
        toast.success("Successfully removed team");
      } catch (e) {
        toast.error("Problem removing team");
      }
    }
  };

  const onKickTeam = async (id, value) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${leagueId}/teams/${id}/kick/${value}`);

      setLeague((league) => ({ ...league, teams: league.teams.map((team) => (team._id === id ? { ...team, kicked: value } : team)) }));
      toast.success(`Successfully ${!value && "un"}kicked team`);
    } catch (e) {
      toast.error("Problem kicking team");
    }
  };

  const onSwitchTeam = (team) => {
    setSwitchingTeam(team);
    setShowSwitchModal(true);
  };
  const editPlaceHolder = async (team) => {
    setEditPlaceHolderTeam(team);
    setShowEditPlaceHolderModal(true);
  };
  const addplaceholder = async () => {
    try {
      const bodyData = {
        name: "Placeholder",
        initials: "PH",
        game: league.game._id,
        organisation: "61dc169b2a8c7f1cb4d65cd2",
        placeHolder: true,
        tournament: league._id,
      };
      const {
        data: { updatedTournament },
      } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}/teams/placeholder`, bodyData);
      setLeague((league) => ({ ...league, ...updatedTournament }));
      toast.success("Successfully created team!");
    } catch (e) {
      toast.error(e?.response?.data?.msg || "There was a problem creating your team");
    }
  };

  return (
    <div>
      {showTeamModal && (
        <AddTeamsModal
          game={league.game}
          show={showTeamModal}
          onHide={() => setShowTeamModal(false)}
          teams={league.teams}
          setLeague={setLeague}
        />
      )}
      {showSwitchModal && (
        <SwitchTeamModal
          game={league.game}
          show={showSwitchModal}
          onHide={() => setShowSwitchModal(false)}
          currentTeam={switchingTeam}
          leagueTeams={league.teams}
          league={league}
          setLeague={setLeague}
        />
      )}
      {showEditPlaceHolderModal && (
        <EditPlaceHolderModal
          game={league.game}
          show={showEditPlaceHolderModal}
          onHide={() => setShowEditPlaceHolderModal(false)}
          currentTeam={editPlaceHolderTeam}
          leagueTeams={league.teams}
          league={league}
          setLeague={setLeague}
        />
      )}

      {/* Teams list */}
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div>Teams ({league.teams.length})</div>
          <Button variant="link" onClick={() => setShowTeamModal(true)} className={permission ? "disabled-link p-0" : "p-0"}>
            Add Team
          </Button>
          <Button variant="link" onClick={addplaceholder} className={permission ? "disabled-link p-0" : "p-0"}>
            Add Placeholder
          </Button>
        </Card.Header>
        <Card.Body>
          {!league.teams.length
            ? "No teams"
            : league.teams.map(({ _id, team, kicked, createdAt, name, initials, logo }) =>
                team ? (
                  <div className="leagues-team" key={_id}>
                    {kicked && (
                      <div className="kicked-overlay">
                        {team.name} is kicked
                        <Button
                          size="sm"
                          variant="outline-danger"
                          className="ml-2"
                          onClick={() => onKickTeam(_id, false)}
                          disabled={permission}
                        >
                          Unkick
                        </Button>
                      </div>
                    )}

                    <Media className={`mb-4 ${kicked && "translucent-overlay"}`}>
                      <img width={50} height={50} className="mr-3" src={team.logo} alt={team.name} />
                      <Media.Body className="d-flex justify-content-between">
                        <div>
                          <a href={`https://leagues.gg/teams/${team._id}`} target="_blank" rel="noopener noreferrer">
                            {team.name}
                          </a>

                          <div className="text-secondary">
                            <small>Added {moment(createdAt).fromNow()}</small>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {team.placeHolder === true && (
                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit PlaceHolder Team</Tooltip>}>
                              <Button variant="link" onClick={() => editPlaceHolder(team)} disabled={permission}>
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </OverlayTrigger>
                          )}
                          {filterTeams.includes(team._id) ? (
                            <div>
                              {/* {(team.placeHolder === true &&
                            < OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit PlaceHolder Team</Tooltip>}>
                              <Button variant="link" onClick={() => editPlaceHolder(team)} disabled={permission}>
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </OverlayTrigger>
                          )} */}
                              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Switch with other team</Tooltip>}>
                                <Button variant="link" className="text-secondary" onClick={() => onSwitchTeam(team)} disabled={permission}>
                                  <FontAwesomeIcon icon={faSync} />
                                </Button>
                              </OverlayTrigger>

                              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Kick from league</Tooltip>}>
                                <Button variant="link" className="text-danger" onClick={() => onKickTeam(_id, true)} disabled={permission}>
                                  <FontAwesomeIcon icon={faBan} />
                                </Button>
                              </OverlayTrigger>
                            </div>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="tooltip">Remove from league</Tooltip>}
                              disabled={permission}
                            >
                              <Button variant="link" className="text-danger" onClick={() => onRemoveTeam(_id)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </Media.Body>
                    </Media>
                  </div>
                ) : (
                  <div className="leagues-team" key={_id}>
                    <Media className="mb-4">
                      <Media.Body className="d-flex justify-content-between">[Deleted]</Media.Body>
                    </Media>
                  </div>
                )
              )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default TeamsListCard;
