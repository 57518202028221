import React, { useState, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AppContext } from "../../../context/AppContext";
import SubTabs from "./SubTabs";
import { useEffect } from "react";
import TranslationGetApis from "../utils/LanguagesGetApi";

const TabsManager = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [translationdata, setTranslationdata] = useState([]);

  useEffect(() => {
    const dataa = async () => {
      setLoading(true);
      const langdata = await TranslationGetApis();
      const z=langdata.sort((a,b) => (a.language > b.language) ? 1 : ((b.language > a.language) ? -1 : 0))
      const firstItem='English'
      const y=z.sort((x,y)=>{ return x.language === firstItem ? -1 : y.language === firstItem ? 1 : 0; })
      setTranslationdata(y)
      setLoading(false);
    };
    dataa();
  }, []);

  return (
    <>
      {loading ? (
        "Loading....."
      ) : (
        <Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example" className="mb-3">
          {translationdata?.map((name, key) => (
            <Tab eventKey={key} key={key} title={name?.language || "No Name"} className={name.language}>
              <SubTabs apidata={translationdata[key]} />
            </Tab>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default TabsManager;
