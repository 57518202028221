import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import EditImageModal from "../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { TIMEZONES } from "./timezones";
import Datetime from "react-datetime";
import { countries } from "../../../../../utils/countries";

const Information = ({ league, setLeague }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [country, setCountry] = useState(league.country.code);
  const [startDate, setStartDate] = useState(league.startDate ? new Date(league.startDate) : null);
  const [nationalities, setNationalities] = useState([]);
  const [modals, setModals] = useState({});
  const [defaultTimeZone, setDefaultTimeZone] = useState(league ? league.timezone : TIMEZONES[40].value);

  const onUpdateImage = async (type, imageUrl) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/image`, { type, image: imageUrl });
      setLeague((league) => ({ ...league, ...data.updatedImages }));
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating league images!");
    }
  };

  useEffect(() => {
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
    if (league.timezone !== undefined && league.timezone !== "") {
      const timezone = TIMEZONES.find((element) => {
        return element.value === league.timezone.trim();
      });
      if (timezone) setDefaultTimeZone(timezone.value);
      else
        setDefaultTimeZone(
          TIMEZONES.find((element) => {
            return element.value === "Etc/UTC";
          }).value
        );
    } else {
      setDefaultTimeZone(
        TIMEZONES.find((element) => {
          return element.value === "ETC/UTC";
        }).value
      );
    }
  }, []);


  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // Country
      if (country) {
        const nationality = nationalities.find((data) => data.alpha2Code === country);
        data = {
          ...data,
          country: { code: country, name: nationality.demonym },
          startDate: startDate ? new Date(startDate).toISOString() : null,
        };
      }
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}`, data);

      // update league
      setLeague((league) => ({ ...league, ...data }));

      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setLoading(false);
  };
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                ref={register({ required: true })}
                type="text"
                id="name"
                name="name"
                defaultValue={league.name}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label>Open for</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                ref={register({ required: true })}
                id="country"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                onBlur={handleSubmit(onSubmit)}
              >
                <option value="">Select country</option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={alpha2Code}>
                    {demonym}
                  </option>
                ))}
              </Form.Control>
              {!!country && (
                <img
                  src={`https://www.flagcdn.com/128x96/${country.toLowerCase()}.png`}
                  width={32}
                  height={32}
                  alt={country}
                  className="ml-2"
                />
              )}
            </div>
            <Form.Control.Feedback type="invalid">{errors.country && errors.country.message}</Form.Control.Feedback>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Time zone</Form.Label>
              <Form.Control
                as="select"
                ref={register({ required: true })}
                value={defaultTimeZone}
                defaultValue={defaultTimeZone}
                id="timezone"
                name="timezone"
                onBlur={handleSubmit(onSubmit)}
                onChange={(e) => setDefaultTimeZone(e.target.value)}
              >
                {TIMEZONES.map(({ name, value }, index) => (
                  <option value={value} key={index}>
                    {name}
                  </option>
                ))}
              </Form.Control>

              <Form.Control.Feedback type="invalid">{errors.timezone && errors.timezone.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Abbreviation</Form.Label>
              <Form.Control
                ref={register()}
                type="text"
                id="abbreviation"
                name="abbreviation"
                defaultValue={league.abbreviation || ""}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.abbreviation && errors.abbreviation.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Division</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="leagueDivision"
                name="leagueDivision"
                defaultValue={league.leagueDivision}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.leagueDivision && errors.leagueDivision.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Division Name</Form.Label>
              <Form.Control
                ref={register()}
                type="text"
                id="leagueDivisionName"
                name="leagueDivisionName"
                defaultValue={league.leagueDivisionName}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.leagueDivisionName && errors.leagueDivisionName.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <div>
                <Datetime
                  // ref={register()}
                  name="startDate"
                  value={startDate}
                  onChange={(data) => setStartDate(data.toDate())}
                  onBlur={handleSubmit(onSubmit)}
                  dateFormat="MMMM Do YYYY"
                  timeFormat={false}
                />
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Estimated Duration</Form.Label>
              <Form.Control
                ref={register()}
                type="text"
                id="estDuration"
                name="estDuration"
                defaultValue={league.estDuration}
                placeholder="Example: 10 weeks"
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.estDuration && errors.estDuration.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Best Of</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="bestOf"
                name="bestOf"
                defaultValue={league.bestOf}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.bestOf && errors.bestOf.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Match Days</Form.Label>
          <Form.Control
            as="textarea"
            ref={register()}
            id="matchDays"
            rows={3}
            name="matchDays"
            defaultValue={league.matchDays}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.matchDays && errors.matchDays.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Discord Link</Form.Label>
          <Form.Control
            ref={register()}
            type="url"
            id="discord"
            name="discord"
            placeholder="Example: https://discord.gg/...."
            defaultValue={league.discord}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.discord && errors.discord.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>YouTube Video</Form.Label>
          <Form.Control
            ref={register()}
            type="url"
            id="video"
            name="video"
            placeholder="Example: https://youtube.com/...."
            defaultValue={league.video}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.video && errors.video.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Twitch Channel</Form.Label>
          <Form.Control
            ref={register()}
            type="url"
            id="twitch"
            name="twitch"
            placeholder="Example: https://twitch.tv/...."
            defaultValue={league.twitch}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.twitch && errors.twitch.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Rules Ghost Slug</Form.Label>
          <Form.Control
            ref={register()}
            type="text"
            id="rulesGhostSlug"
            name="rulesGhostSlug"
            placeholder="Example: leagues-gg-rules-2"
            defaultValue={league.rulesGhostSlug}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.rulesGhostSlug && errors.rulesGhostSlug.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>About</Form.Label>
          <Form.Control
            as="textarea"
            ref={register()}
            id="about"
            rows={10}
            name="about"
            defaultValue={league.about}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.about && errors.about.message}</Form.Control.Feedback>
        </Form.Group>

        <div>
          <div className="d-flex">
            {/* Add new images to the array */}
            {[
              { name: "Logo", key: "logoImage", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
              { name: "Thumbnail", key: "thumbnailImage", width: 500, height: 300, displayWidth: 250, displayHeight: 150, crop: true },
              { name: "Cover", key: "coverImage", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />

                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${league[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
        </div>

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default Information;
