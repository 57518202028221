import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from "react-toastify";

const RoleCreateEditModal = ({ show, handleClose, modalData, setRoles }) => {
  const [creating, setCreating] = useState(false)
  const [roleType, setRoleType] = useState("");
  const [switches, setSwitches] = useState({
    admin: false,
    entityData: false,
    leaguesAndTournaments: false,
    creation: false
  })
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    setRoleType(modalData.type ? modalData.type : "")
    if (modalData.type === "User") {
      setSwitches({
        ...switches,
        admin: modalData && modalData.user.admin,
        entityData: modalData && modalData.user.entityData,
        leaguesAndTournaments: modalData && modalData.user.leaguesAndTournaments,
        creation: modalData && modalData.user.creation
      })
    }
  }, [modalData])

  const onSubmit = async (data) => {
    try {
      setCreating(true);
      let organisation = {};
      let user = {};

      if (roleType === "User") {
        user = {
          calendar: data.calendar,
          analyzer: data.analyzer,
          sms: data.sms,
          admin: data.admin,
          frontPage: data.frontPage,
          entityData: data.entityData,
          permissions: data.permissions,
          roles: data.roles,
          game: data.game,
          advertisements: data.advertisements,
          leaguesAndTournaments: data.leaguesAndTournaments,
          matches: data.matches,
          settings: data.settings,
          creation: data.creation,
          seasons: data.seasons,
          groups: data.groups,
          scoreSystem: data.scoreSystem,
          scrims: data.scrims,
          errorLogs: data.errorLogs
        }
      } else {
        organisation = {
          calendar: data.calendarOrg,
          analyzer: data.analyzerOrg,
          contracts: data.contracts,
          sms: data.smsOrg,
        }
      }

      const createdData = {
        name: data.name,
        type: data.type,
        description: data.description,
        user,
        organisation
      }

      if (modalData) {
        // if Edit
        createdData.type = modalData.type;

        if (!createdData.user.admin) {
          createdData.user.frontPage = false;
          createdData.user.entityData = false;
          createdData.user.permissions = false;
          createdData.user.roles = false;
          createdData.user.game = false;
          createdData.user.advertisements = false;
          createdData.user.leaguesAndTournaments = false;
          createdData.user.matches = false;
          createdData.user.settings = false;
          createdData.user.creation = false;
          createdData.user.seasons = false;
          createdData.user.groups = false;
          createdData.user.scoreSystem = false;
          createdData.user.scrims = false;
          createdData.user.errorLogs = false;
        }
        if (!createdData.user.entityData) {
          createdData.user.permissions = false;
          createdData.user.roles = false;
        }
        if (!createdData.user.leaguesAndTournaments) {
          createdData.user.matches = false;
          createdData.user.settings = false;
          createdData.user.creation = false;
        }

        if (!createdData.user.creation) {
          createdData.user.seasons = false;
          createdData.user.groups = false;
        }
        if (createdData.name === modalData.name) delete createdData.name
        const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/roles/${modalData._id}`, {
          createdData
        });

        // update role in parent state
        setRoles((roles) => roles.map((role) => (role._id === modalData._id ? { ...role, ...newData.updatedRole } : role)));
        toast.success("Successfully Edited Role");
      } else {
        // if Create
        const { data: rolesData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/roles`, { createdData });

        // Update parent state
        setRoles((roles) => [...roles, rolesData.role]);
        toast.success("Successfully Created Role");
      }

      setRoleType("");
      handleClose();
      setCreating(false);
    } catch (err) {
      const msg = err.response.data ? err.response.data.msg : "There was a problem creating this role";
      toast.error(msg);
      setCreating(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalData ? "Edit" : "Create"} Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  ref={register({ required: "Required" })}
                  type="text"
                  id="name"
                  name="name"
                  defaultValue={modalData ? modalData.name : ""}
                  placeholder="Role Name"
                  isInvalid={errors.name}
                  // disabled={modalData} Comment to allow edit role name
                />
                <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Role Type</Form.Label>
                <Form.Control
                  as="select"
                  ref={register({ required: "Required" })}
                  name="type"
                  id="type"
                  defaultValue={modalData ? modalData.type : ""}
                  onChange={(e) => setRoleType(e.target.value)}
                  isInvalid={errors.type}
                  disabled={modalData}
                >
                  <option value="" disabled>
                    Choose Role
                  </option>
                  {[
                    "User",
                    "Organisation",
                  ].map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.type && errors.type.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {roleType === "Organisation" ?
            <Row>
              <Col md={6}>
                <Form.Check
                  ref={register()}
                  type="switch"
                  label="Calendar"
                  id="calendarOrg"
                  name="calendarOrg"
                  defaultChecked={modalData && modalData.organisation.calendar}
                />
              </Col>
              <Col md={6}>
                <Form.Check
                  ref={register()}
                  type="switch"
                  label="Analyzer"
                  id="analyzerOrg"
                  name="analyzerOrg"
                  defaultChecked={modalData && modalData.organisation.analyzer}
                />
              </Col>
              <Col md={6}>
                <Form.Check
                  ref={register()}
                  type="switch"
                  label="SMS"
                  id="smsOrg"
                  name="smsOrg"
                  defaultChecked={modalData && modalData.organisation.sms}
                />
              </Col>
              <Col md={6}>
                <Form.Check
                  ref={register()}
                  type="switch"
                  label="Contracts"
                  id="contracts"
                  name="contracts"
                  defaultChecked={modalData && modalData.organisation.contracts}
                />
              </Col>
            </Row> : ""}

          {roleType === "User" ?
            <>
              <Form.Check
                ref={register()}
                type="switch"
                label="Calendar"
                id="calendar"
                name="calendar"
                className="roleSwitches"
                defaultChecked={modalData && modalData.user.calendar}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Analyzer"
                id="analyzer"
                name="analyzer"
                className="roleSwitches"
                defaultChecked={modalData && modalData.user.analyzer}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="SMS"
                id="sms"
                name="sms"
                className="roleSwitches"
                defaultChecked={modalData && modalData.user.sms}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Admin"
                id="admin"
                name="admin"
                className="roleSwitches"
                onChange={(e) => setSwitches({ ...switches, admin: e.target.checked })}
                defaultChecked={modalData && modalData.user.admin}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Front Page"
                id="frontPage"
                name="frontPage"
                className="roleSwitchesParent"
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.frontPage}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Entity Data"
                id="entityData"
                name="entityData"
                className="roleSwitchesParent"
                onChange={(e) => setSwitches({ ...switches, entityData: e.target.checked })}
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.entityData}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Permissions"
                id="permissions"
                name="permissions"
                className="roleSwitchesChild"
                disabled={!switches.admin || !switches.entityData}
                defaultChecked={modalData && modalData.user.permissions}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Roles"
                id="roles"
                name="roles"
                className="roleSwitchesChild"
                disabled={!switches.admin || !switches.entityData}
                defaultChecked={modalData && modalData.user.roles}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Games"
                id="game"
                name="game"
                className="roleSwitchesParent"
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.game}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Advertisments"
                id="advertisements"
                name="advertisements"
                className="roleSwitchesParent"
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.advertisements}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Leagues and Tournaments"
                id="leaguesAndTournaments"
                name="leaguesAndTournaments"
                className="roleSwitchesParent"
                onChange={(e) => setSwitches({ ...switches, leaguesAndTournaments: e.target.checked })}
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.leaguesAndTournaments}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Matches"
                id="matches"
                name="matches"
                className="roleSwitchesChild"
                disabled={!switches.admin || !switches.leaguesAndTournaments}
                defaultChecked={modalData && modalData.user.matches}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Settings"
                id="settings"
                name="settings"
                className="roleSwitchesChild"
                disabled={!switches.admin || !switches.leaguesAndTournaments}
                defaultChecked={modalData && modalData.user.settings}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Creation"
                id="creation"
                name="creation"
                className="roleSwitchesChild"
                onChange={(e) => setSwitches({ ...switches, creation: e.target.checked })}
                disabled={!switches.admin || !switches.leaguesAndTournaments}
                defaultChecked={modalData && modalData.user.creation}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Seasons"
                id="seasons"
                name="seasons"
                className="roleSwitchesChild"
                disabled={!switches.admin || !switches.creation }
                defaultChecked={modalData && modalData.user.seasons}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Groups"
                id="groups"
                name="groups"
                className="roleSwitchesChild"
                disabled={!switches.admin || !switches.creation}
                defaultChecked={modalData && modalData.user.groups}
              />
              
              <Form.Check
                ref={register()}
                type="switch"
                label="Score System"
                id="scoreSystem"
                name="scoreSystem"
                className="roleSwitchesParent"
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.scoreSystem}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Scrims"
                id="scrims"
                name="scrims"
                className="roleSwitchesParent"
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.scrims}
              />
              <Form.Check
                ref={register()}
                type="switch"
                label="Error Logs"
                id="errorLogs"
                name="errorLogs"
                className="roleSwitchesParent"
                disabled={!switches.admin}
                defaultChecked={modalData && modalData.user.errorLogs}
              />
            </> : ""}

          <Form.Group style={{ marginTop: "0.5em" }}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              ref={register({ maxLength: 5000 })}
              as="textarea"
              name="description"
              id="description"
              rows={3}
              placeholder="Write something about Role"
              defaultValue={modalData ? modalData.description : ""}
              isInvalid={errors.description}
            />
            <Form.Control.Feedback type="invalid">{errors.description && errors.description.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="primary" className="roleModalButton">
            {!modalData ? creating ? "Creating Role..." : "Create Role" : "Edit Role"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal >
  );
};

export default RoleCreateEditModal;
