import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { countries } from "../../../utils/countries";
import { useForm } from "react-hook-form";
import UpdateSettingsApi from "../utils/UpdateSettings";
import { toast } from "react-toastify";
import { xorBy } from "lodash";

const Settings = ({langid}) => {
  const [langName, setLangName] = useState();
  const [langCode, setLangCode] = useState();
  const [langTranslate, setLangTranslate] = useState();
  const [isAailable, setIsAailable] = useState(langid.isavailable	);
  const { register, handleSubmit, errors } = useForm();
  const [countryflag, setCountryFlag] = useState(`${langid.flag}`);
  const [nationalities, setNationalities] = useState([]);
  const [LanguageChange, setLanguageChange] = useState(langid.language);
  const [CountryCode, setCountryCode] = useState(langid.langCode);
  const [EndonymChange, setEndonymChange] = useState(langid.endonym);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  const SavePopup = async (id) => {
    const data = {
      isavailable: isAailable,
      language: LanguageChange,
      _id: id,
      endonym: EndonymChange,
      flag: `${countryflag}`,
      langCode: CountryCode,
    };
    if (isAailable.length < 1|| LanguageChange.length < 1|| EndonymChange.length < 1|| countryflag.length< 1 || CountryCode.length < 1){
      window.alert("Admin should be Edit")
    }
    else{
    setSubmitting(true)
    const response= await UpdateSettingsApi(data);
     setSubmitting(false)
   if (!response.error){
     toast.success("Setting Saved!");
     window.location.reload();
   }else{
     console.log(response.error)
     toast.error("Try again! Setting are not Saved yet!");
   }
  }
  };

  return (
    <>
      <Row>
        <Col md={2}>
          <Form.Group>
            <Form.Check
              ref={register()}
              type="checkbox"
              name="checkbox"
              id="checkIsAvailable"
              checked={isAailable}
              label="available"
              onChange={() => setIsAailable(!isAailable)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Endonym</Form.Label>
            <Form.Control
              ref={register()}
              required
              type="text"
              id="setStage"
              name="setStage"
              maxLength={30}
              value={langName}
              defaultValue={langid.endonym}
              onChange={(e) =>  setEndonymChange(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Language</Form.Label>
            <Form.Control
              // ref={register()}
              required
              type="text"
              id="setStage"
              name="setStage"
              maxLength={30}
              value={langCode}
              defaultValue={langid.language}
              onChange={(e) => setLanguageChange(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Flag</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                ref={register({ required: true })}
                id="country"
                name="country"
                value={`https://www.flagcdn.com/128x96/${countryflag.toLowerCase()}.png`}
                defaultValue={`https://www.flagcdn.com/128x96/${countryflag.toLowerCase()}.png`}
                onChange={(e) => setCountryFlag(e.target.value)}
                isInvalid={errors.country}
              >
                <option value={countryflag}>{countryflag}</option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={alpha2Code}>
                    {demonym}
                  </option>
                ))}
              </Form.Control>
              {!!countryflag && <img src={`https://www.flagcdn.com/128x96/${countryflag.toLowerCase()}.png`} width={32} height={32} alt={countryflag} className="ml-2" />}
            </div>
            <Form.Control.Feedback type="invalid">{errors.country && errors.country.message}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Language Code</Form.Label>
            <Form.Control
              ref={register()}
              required
              type="text"
              id="setStage"
              name="setStage"
              maxLength={30}
              value={langTranslate}
              defaultValue={langid.langCode}
              onChange={(e) => setCountryCode(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Button onClick={()=>SavePopup(langid._id)}>{submitting ? "Saving..." : "Save"}</Button>
        </Col>
      </Row>
    </>
  );
};
export default Settings;
