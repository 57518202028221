import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Settings from "../Settings.js/Settings";
import { Form, Button, Row, Col } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import TranslationTable from "../Table/Table";
import DeleteLangApi from "../utils/DeleteLangApi";
const SubTabs = ({ apidata }) => {
  const [langid, setLangId] = useState()
  const [activeTab, setActiveTab] = useState("Translation");
  
  const deleteClick = async(e) => {
    if (window.confirm("Are you sure you want to delete this Language? This action is irreversible!")) {
  const response = await DeleteLangApi(e)
   if (response){
    toast.success("Delete successfully");
    window.location.reload();
  }else{
    toast.error("Try again! Language is not deleted yet");
  }
}
  }
  return (
    <>
      <div className="d-flex justify-content-end mb-0">
        {apidata?.isDefault === true ? null : (
          <Button variant="outline-danger" onClick={() => deleteClick(apidata._id)} style={{ marginBottom: "-30px" }}  >
            <BsTrash style={{ marginBottom: "3px" }} /> Delete
          </Button>
        )}
      </div>
      <Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey={"Translation"} title={"Translation"}>
          <TranslationTable Apidata={apidata} />
        </Tab>
        <Tab eventKey={"Settings"} title={"Settings"}>
          <Settings langid={apidata} />
        </Tab>
      </Tabs>
    </>
  );
};

export default SubTabs;
